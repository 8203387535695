#gameStatsModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statsModalRow {
  display: flex;
  flex-direction: row;
}

.statsModalRow:not(:last-child) {
  margin-bottom: 10px;
}

.nextGameCountdownContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userStat {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
}

.userStatsBlock {
  color: black;
  background-color: var(--light-gray);
}

.successUserStatsBlock {
  outline-color: green;
  color: white;
  background-color: green;
}

.userStatLabel {
  padding-top: 4px;
  font-size: 1rem;
  text-align: center;
}

.shareTodaysScoreButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
}

.shareTodaysScoreButton {
  display: flex;
  flex-direction: row;
  width: 100px;
  height: 40px;
  justify-content: center;
  align-items: center;
  fill: white;
  background: rgba(0, 128, 0, 0.8);
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  border-radius: 4px;
}

.shareButtonText {
  margin-right: 4px;
}

.shareTodaysScoreButton svg {
  width: 24px;
  height: 24px;
}

.shareTodaysScoreButtonCounterDummyDiv {
  width: 100px;
  height: 50px;
}

.playRandomGameButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
}

.archivedModalPlayRandomGameButtonContainer {
  margin-right: 8px;
}

.playRandomGameButton {
  display: flex;
  flex-direction: row;
  width: 120px;
  height: 40px;
  justify-content: center;
  align-items: center;
  fill: white;
  background: var(--purple);
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  border-radius: 4px;
}

.playRandomGameButton svg {
  width: 24px;
  height: 24px;
}

.playRandomGameButtonText {
  margin-right: 4px;
}

.playTodayGameButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
}

.playTodayGameButton {
  display: flex;
  flex-direction: row;
  width: 100px;
  height: 40px;
  justify-content: center;
  align-items: center;
  fill: white;
  background: rgba(0, 128, 0, 0.8);
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  border-radius: 4px;
}

.playTodayGameButton svg {
  width: 24px;
  height: 24px;
}

.playTodayGameButtonText {
  margin-right: 4px;
}