.gameHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: var(--header-height);
  border-bottom: 1px solid var(--light-gray);
  padding: 0 10px;
}

.archivedGameHeader {
  background-color: var(--light-purple);
}

.gameHeaderLeftGroup {
  display: flex;
  flex-direction: row;
  width: 70px;
  justify-content: flex-start;
}

.gameHeaderRightGroup {
  display: flex;
  flex-direction: row;
  width: 70px;
  justify-content: flex-end;
}

.gameTitle {
  font-size: 2.2rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 4px;
}

.iconButton svg {
  width: 24px;
  height: 24px;
}