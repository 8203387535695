.overlay {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background-color: var(--opacity-50);
  z-index: 1000;
}

.overlayOpen {
  display: flex;
}

.overlayContent {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.modal {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 90%;
  max-width: var(--max-game-width);
  max-height: 90%;
  background-color: white;
  align-items: center;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.2);
  padding: 10px 0px 20px 0px;
  border-radius: 4px;
  overflow: auto;
}

.modalContent {
  overflow: auto;
  width: 100%;
}

.modalHeaderRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 10px;
}

.modalTitle {
  font-size: 2rem;
  font-weight: bold;
  justify-self: center;
}

.dummyLeftItem {
  width: 50px;
}

.closeModalButton {
  align-self: start;
  width: 50px;
  background: none;
  border: none;
  cursor: pointer;
}

.closeModalButton svg {
  width: 24px;
  height: 24px;
}