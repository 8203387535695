.headerAdUnit {
  display: block;
  width: 100%;
  height: var(--header-ad-unit-height-mobile);
}

@media(min-width: 800px) { 
  .headerAdUnit { 
    height: var(--header-ad-unit-height-desktop);
  } 
}