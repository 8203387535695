.gameBoard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  overflow: auto;
}

.inputRowsContainer {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.row {
  display: flex;
  justify-content: center;
}

.inputRowsContainer .row:not(:nth-last-child(2)) {
  padding-bottom: 10px;
}

.inputRowsEndElement {
  float:"left";
  clear: "both";
}

.startWordRowContainer {
  width: 100%;
  padding-bottom: 20px;
}

.endWordRowContainer {
  width: 100%;
  padding-top: 20px;
}

.block {
  display: inline-flex;
  width: 60px;
  height: 60px;
  outline: 2px solid var(--light-gray);
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
}

.row .block:not(:last-child) {
  margin-right: 10px;
}

.filledBlock {
  color: black;
}

.currentBlock {
  outline-color: black;
}

.transitionBlock {
  outline-color: black;
}

.correctBlock {
  background-color: var(--light-green);
}

.startWordBlock {
  color: black;
  background-color: var(--light-gray);
}

.endWordBlock {
  color: black;
  background-color: var(--light-gray);
}

.endWordBlockComplete {
  outline-color: green;
  color: white;
  background-color: green;
}

.exampleWordBlock {
  color: black;
  background-color: var(--light-gray);
}

.bouncingBlock {
  animation: bounce 2s infinite;
}

.endWordRow .bouncingBlock:nth-child(1) {
  animation-delay: 50ms;
}

.endWordRow .bouncingBlock:nth-child(2) {
  animation-delay: 100ms;
}

.endWordRow .bouncingBlock:nth-child(3) {
  animation-delay: 150ms;
}

.endWordRow .bouncingBlock:nth-child(4) {
  animation-delay: 200ms;
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  10% {
    transform: translateY(-15px);
  }
  20% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}

.clearBoardButton {
  font-size: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}